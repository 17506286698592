<template>
    <div>
        <ul class="nav nav-tabs mb-3" :class="{ 'd-none': authSteps.length < 2 }" role="tablist">
            <li class="nav-item" role="presentation" v-for="step in authSteps" >
                <button @click="updateScheme(step.name)" class="nav-link" :class="{ active: scheme.localeCompare(step.name, undefined, { sensitivity: 'accent' }) == 0, disabled : props.state.isAuthenticated }" data-bs-toggle="tab" 
                    :id="`${step.name}-tab`" :data-bs-target="`#${step.name}`" type="button" role="tab" :aria-controls="`${step.name}`" aria-selected="true">
                    <b>{{ step.title }}</b>
                </button>
            </li>
        </ul>
        <div class="tab-content">                
            <div class="tab-pane fade" v-for="step in authSteps" :key="step.name" :class="{ 'active show': scheme.localeCompare(step.name, undefined, { sensitivity: 'accent' }) == 0 }" :id="`${step.name}`" role="tabpanel" 
                :aria-labelledby="`${step.name}-tab`">
                <component :is="step.component" :name="step.name" :state="state" :errors="errors" :title="step.title" :capsLockOn="capsLockOn" />
            </div>
        </div>
    </div>
</template>

<style>
.nav-link {
    border-bottom: 1px solid var(--bs-border-color)!important; /* var(--bs-nav-tabs-link-active-border-color) */
}
ul.nav-tabs {
    --bs-nav-tabs-border-color: none;
}
.nav-link.active {
    border-bottom-color: transparent!important; /* Remove the bottom border from the active tab link */
}
</style>

<script setup>
    import { computed, onMounted } from 'vue';
    import { getComponentFromName, getTitle } from 'o365.modules.Login.shared.js';

    onMounted(async() => {
        console.log(authSteps.value);     
    })

    const props = defineProps({
        state: { type: Object, required: true },
        capsLockOn: { type: Boolean, default: false },
        errors: { type: Object, required: false, default: Object },
    });

    const scheme = computed( () => { 
        var tmp = localStorage.getItem('scheme') ?? props.state.scheme ?? authSteps.value[0].name;
          
        var last = authSteps.value.find((e) => e.name == tmp);
        
        return last?.name ?? authSteps.value[0].name;        
    });


    const authSteps = computed(() => {
        return Object.entries(props.state.authentication ?? {}).filter(([k, v]) => v.enabled && v.hidden != true).map(([k, v]) => ({
            name: k,
            mfa: v.requireTwoFactor,
            component: getComponentFromName(k),
            title: v.title ?? getTitle(k),
            index: v.index
        })).sort((a, b) => {
            if (a.index === null && b.index === null) {
                return 0;
            } else if (a.index === null) {
                return 1;
            } else if (b.index === null) {
                return -1;
            } else {
                return a.index - b.index;
            }
        });
    });

    function updateScheme(scheme){
        localStorage.setItem('scheme', scheme);
    }
    
</script>